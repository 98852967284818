export const validatePhoneNumber = (
  countryCode: string,
  phoneNumber: string
) => {
  const phoneNumberRegexMap: { [key: string]: RegExp } = {
    "+44": /^[0-9]{10}$/, // UK
    "+91": /^[0-9]{10}$/, // India
    "+1": /^[0-9]{10}$/, // USA
    "+61": /^[0-9]{9,10}$/, // Australia
    "+995": /^[0-9]{9}$/, // Georgia
    "+90": /^[0-9]{10}$/, // Turkey
  };

  // Check if the provided country code is in the map
  if (phoneNumberRegexMap.hasOwnProperty(countryCode)) {
    const regexPattern = phoneNumberRegexMap[countryCode];
    if (!regexPattern.test(phoneNumber.trim())) {
      return `Please enter a valid mobile number`;
    }
  } else {
    // Default validation for unknown country codes
    const defaultPhoneNumberRegex = /^[0-9]+$/;
    if (!defaultPhoneNumberRegex.test(phoneNumber.trim())) {
      return "Please enter a valid mobile number.";
    }
  }
  return null;
};

export const countryCodes = ["+44", "+91", "+1", "+61", "+995", "+90"];
