import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAsync, useAsyncEffect } from "../utils/use-async";
import { ApiClientContext } from "../utils/ApiContext";
import { Draft, produce } from "immer";
import { toast } from "react-toastify";
import { createRequestDTO } from "../utils/CreateRequest";
import QuiltPageComponent, {
  FormFieldType,
} from "../components/QuiltPageComponent";
import { validatePhoneNumber } from "../utils/ValidationOfMobileNumber";

export type AddFormFieldState = {
  firstName: string;
  lastName: string;
  countryCode: string;
  phoneNumber: string;
};

export type AddRequestDTO = {
  firstName: string;
  lastName: string;
  countryCode: string;
  ethnicity: string;
  email: string;
  phoneNumber: string;
  externalPatientId: string;
  doctorId: string;
  clinicId: string;
  conditions: string;
};

const QuiltPageContainer: React.FC = () => {
  const apiClient = useContext(ApiClientContext)!;

  const location = useLocation();
  const navigation = useNavigate();

  const [encodedText, setEncodedText] = useState("");
  const [consentChecked, setConsentChecked] = useState(false);

  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const encodedText = queryParams.get("token");

    if (encodedText) {
      setEncodedText(encodedText);
    }
  }, [location.search]);

  const [result] = useAsyncEffect({
    fn: async () => {
      if (encodedText) {
        const result = await apiClient.get("/authorize", {
          params: {
            token: encodedText,
          },
        });
        return result.data;
      }
    },
    dependencies: [encodedText],
  });

  const { rtiId, clinicName, adminDoctorId, clinicId } = result.result || {};

  const [formFieldState, setFormFieldState] = useState<AddFormFieldState>({
    firstName: "",
    lastName: "",
    countryCode: "+1",
    phoneNumber: "",
  });

  const handleFormFieldChange = (type: FormFieldType, value: string) => {
    setFormFieldState((prevState) => {
      return produce(prevState, (draft: Draft<AddFormFieldState>) => {
        switch (type) {
          case "country-code":
            draft.countryCode = value;
            break;
          case "phone-number":
            draft.phoneNumber = value;
            break;
          case "first-name":
            draft.firstName = value;
            break;
          case "last-name":
            draft.lastName = value;
            break;
          default:
            throw new Error("Unsupported field type");
        }
      });
    });
  };
  const [registerPatientResult, registerPatientCallable] = useAsync<string>({
    fn: async (details: AddRequestDTO) => {
      const url = "/register-patient";

      const result = await apiClient.post<string>(url, details, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "cvORMtwPsf_IZjek33PQbp_bNsw2duf77VXPRr074UE=",
        },
      });
      return result.data;
    },
  });

  useEffect(() => {
    if (registerPatientResult.result) {
      try {
        const resultObject = registerPatientResult.result;

        if (typeof resultObject === "object") {
          const { errorCode, message } = resultObject;

          if (errorCode === 400 && message === "failed") {
            alert("Mobile number is already registered.");
          } else {
            navigation("/download-app");
          }
        } else {
          alert("Invalid result");
        }
      } catch (error) {}
    }
  }, [registerPatientResult.result]);

  useEffect(() => {
    if (registerPatientResult.error) {
      toast.error(registerPatientResult.error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light",
      });
    }
  }, [registerPatientResult.error]);

  const handleOnSubmit = async () => {
    // Validation for First Name presence
    if (formFieldState.firstName.trim() === "") {
      toast.error("Please enter first name.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light",
      });
      return;
    }

    // Validation for First Name containing only alphabets and spaces
    if (!/^[a-zA-Z ]+$/.test(formFieldState.firstName.trim())) {
      toast.error("First name should only contain alphabets.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light",
      });
      return;
    }

    // Validation for Last Name presence
    if (formFieldState.lastName.trim() === "") {
      toast.error("Please enter last name.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light",
      });
      return;
    }

    // Validation for Last Name containing only alphabets and spaces
    if (!/^[a-zA-Z ]+$/.test(formFieldState.lastName.trim())) {
      toast.error("Last name should only contain alphabets.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light",
      });
      return;
    }
    // Validation for Country Code presence
    if (!formFieldState.countryCode.trim()) {
      toast.error("Please select a country code.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light",
      });
      return;
    }

    // Validation for Mobile Number presence
    if (formFieldState.phoneNumber.trim() === "") {
      toast.error("Please enter a mobile number.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light",
      });
      return;
    }

    // Validate the mobile number based on the selected country code
    const phoneNumberValidationResult = validatePhoneNumber(
      formFieldState.countryCode,
      formFieldState.phoneNumber
    );

    if (phoneNumberValidationResult) {
      toast.error(phoneNumberValidationResult, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "light",
      });
      return;
    }

    const addRequestBody = createRequestDTO(
      formFieldState,
      rtiId,
      adminDoctorId,
      clinicId
    );
    await registerPatientCallable(addRequestBody);
  };

  const handleGetQuiltClick = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleOnCheckBoxClick = () => {
    setConsentChecked(!consentChecked);
  };

  return (
    result && (
      <QuiltPageComponent
        isLoading={result.isLoading}
        isResultLoading={registerPatientResult.isLoading}
        hasError={result.error}
        rtiId={rtiId}
        clinicName={clinicName}
        onFormFieldChange={handleFormFieldChange}
        formState={formFieldState}
        formRef={formRef}
        consent={consentChecked}
        onGetQuiltClick={handleGetQuiltClick}
        onSubmit={handleOnSubmit}
        onCheckboxSelect={handleOnCheckBoxClick}
      />
    )
  );
};

export default QuiltPageContainer;
