import React from "react";
import Logo from "../assets/quiltLogo.png";
import Face from "../assets/face.svg";
import Card1 from "../assets/card1.svg";
import Card2 from "../assets/card2.svg";
import Card3 from "../assets/card3.svg";
import Card4 from "../assets/card4.svg";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { AddFormFieldState } from "../container/QuiltPageContainer";
import { countryCodes } from "../utils/ValidationOfMobileNumber";

export type FormFieldType =
  | "country-code"
  | "phone-number"
  | "first-name"
  | "last-name";

type DetailsPageProps = {
  isLoading: boolean;
  hasError: boolean;
  isResultLoading: boolean;
  rtiId: string;
  clinicName: string;
  formState: AddFormFieldState;
  formRef: React.RefObject<HTMLDivElement>;
  consent: boolean;
  onFormFieldChange(type: FormFieldType, value: string): void;
  onGetQuiltClick(): void;
  onCheckboxSelect(): void;
  onSubmit(): void;
};

const styles: { [key: string]: React.CSSProperties } = {
  app: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  topSection: {
    width: "100%",
    overflow: "hidden",
  },
  purpleBackground: {
    backgroundColor: " #40256B",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5vw",
  },
  purpleText: {
    fontSize: "6vw",
    fontWeight: 700,
    lineHeight: "6vw",
    textAlign: "center",
    color: "white",
  },
  centerText: {
    backgroundColor: "white",
    fontSize: "4.5vw",
    fontWeight: 700,
    lineHeight: "5vw",
    textAlign: "center",
  },
  cardsSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "90%",
  },
  card: {
    border: "1px solid #AFAFAF",
    borderRadius: "10px",
    padding: "4vw",
    marginBottom: "5vw",
  },
  textBelowCards: {
    textAlign: "center",
  },
  formFields: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    width: "90%",
  },
  input: {
    width: "100%",
    marginBottom: "10vw",
    color: "#8C8CA1",
  },
  button: {
    background: "#EE2D76",
    boxShadow: "0px 6px 2px -4px #0E0E2C1A, 0px -1px 0px 0px #0E0E2C66 inset",
    padding: "4vw",
    width: "100%",
    borderRadius: "5px",
    cursor: "pointer",
    border: "none",
    fontSize: "3.5vw",
    fontWeight: 700,
    lineHeight: "5vw",
    letterSpacing: "0.04em",
    textAlign: "center",
    color: "white",
    marginBottom: "3vw",
  },
  submitButton: {
    background: "#EE2D76",
    boxShadow: "0px 6px 2px -4px #0E0E2C1A, 0px -1px 0px 0px #0E0E2C66 inset",
    padding: "4vw",
    width: "100%",
    borderRadius: "5px",
    cursor: "pointer",
    border: "none",
    fontSize: "3.5vw",
    fontWeight: 700,
    lineHeight: "5vw",
    letterSpacing: "0.04em",
    textAlign: "center",
    color: "white",
    marginTop: "5vw",
    marginBottom: "10vw",
  },
  submitButtonStyle: {
    background: "grey",
    boxShadow: "0px 6px 2px -4px #0E0E2C1A, 0px -1px 0px 0px #0E0E2C66 inset",
    padding: "4vw",
    width: "100%",
    borderRadius: "5px",
    cursor: "pointer",
    border: "none",
    fontSize: "3.5vw",
    fontWeight: 700,
    lineHeight: "5vw",
    letterSpacing: "0.04em",
    textAlign: "center",
    color: "white",
    marginTop: "5vw",
    marginBottom: "10vw",
  },
  logoImage: {
    width: "15vw",
    marginBottom: "5vw",
  },
  faceImage: {
    width: "40vw",
  },
  cardImage: {
    width: "25vw",
  },
  descriptionText: {
    fontSize: "4vw",
    fontWeight: 400,
    lineHeight: "5.5vw",
    letterSpacing: "0.02em",
    textAlign: "left",
    color: "#757575",
  },
  formBodyText: {
    fontSize: "4vw",
    fontWeight: 400,
    lineHeight: "5vw",
    letterSpacing: "0.02em",
    textAlign: "center",
    color: "#757575",
  },
  countryCode: {
    width: "25%",
    marginRight: "2vw",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
};

const QuiltPage: React.FC<DetailsPageProps> = ({
  isLoading,
  hasError,
  isResultLoading,
  rtiId,
  clinicName,
  formState,
  formRef,
  consent,
  onFormFieldChange,
  onGetQuiltClick,
  onCheckboxSelect,
  onSubmit,
}) => {
  return (
    <div style={styles.app}>
      {/* Top Section */}
      <div style={styles.topSection}>
        {/* Purple background */}
        <div style={styles.purpleBackground}>
          <img style={styles.logoImage} src={Logo} alt="Quilt Icon" />
          <img style={styles.faceImage} src={Face} alt="Quilt" />
          <p style={styles.purpleText}>
            Quilt is here to help <br></br>you stay on top of <br></br>OCD as a
            Sustained <br></br>Conqueror.
          </p>
          <button style={styles.button} onClick={onGetQuiltClick}>
            Get Quilt<sup style={{ verticalAlign: "super" }}>beta</sup>
          </button>
        </div>
      </div>

      {/* Center Text */}
      <div style={styles.centerText}>
        <p>The way it works is simple:</p>
      </div>

      {/* Cards Section */}
      <div style={styles.cardsSection}>
        <div style={styles.card}>
          <img src={Card1} style={styles.cardImage} alt="1" />
          <p style={styles.descriptionText}>
            Earn tokens for walking, spending less time on your phone at night,
            completing symptom check-ins, and other behaviors that support your
            long-term mental health!
          </p>
        </div>
        <div style={styles.card}>
          <img src={Card2} style={styles.cardImage} alt="2" />
          <p style={styles.descriptionText}>
            Redeem your tokens to play games, progress, and earn rewards in the
            Quilt app. Games range in style from Wordle and Tetris to
            faster-paced driving and action games!
          </p>
        </div>
        <div style={styles.card}>
          <img src={Card3} style={styles.cardImage} alt="3" />
          <p style={styles.descriptionText}>
            Quilt monitors your symptom check-ins and other data to keep you and
            your team at NOCD informed. Whenever Quilt sees an increase in
            symptoms, your team at NOCD is notified so that they can help you
            nip an episode in the bud, before it worsens.
          </p>
        </div>
        <div style={styles.card}>
          <img src={Card4} style={styles.cardImage} alt="4" />
          <p style={styles.descriptionText}>
            We want to build what's most engaging for you, defined by you! Based
            on your feedback, Quilt will continue to develop and improve its
            platform each week. Fill in the information below to sign up as a
            Quilt beta tester and game your way to sustainable wellbeing.
          </p>
        </div>
      </div>
      {isLoading ? (
        <CircularProgress ref={formRef} />
      ) : hasError || !rtiId || !clinicName ? (
        <p ref={formRef}>Some error occurred</p>
      ) : (
        <>
          {/* Text Below Cards */}
          <div ref={formRef} id={"form"} style={styles.textBelowCards}>
            <p style={styles.centerText}>Download Quilt</p>
            <p style={styles.formBodyText}>
              Please fill out the form below and download<br></br>the Quilt app
            </p>
          </div>

          {/* Form Fields */}
          <div style={styles.formFields}>
            {/* <TextField
              label="RT ID"
              variant="outlined"
              style={styles.input}
              value={rtiId}
              contentEditable={false}
              disabled={true}
            />
            <TextField
              label="Clinic name"
              variant="outlined"
              style={styles.input}
              value={clinicName}
              contentEditable={false}
              disabled={true}
            /> */}
            <div style={styles.flexRow}>
              <TextField
                select
                value={formState.countryCode}
                variant="outlined"
                style={{ ...styles.input, ...styles.countryCode }}
                onChange={(event) =>
                  onFormFieldChange("country-code", event.target.value)
                }
              >
                {countryCodes.map((code) => (
                  <MenuItem key={code} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Mobile Number"
                placeholder="Mobile number"
                variant="outlined"
                style={{ ...styles.input, flex: 1 }}
                value={formState.phoneNumber}
                onChange={(event) =>
                  onFormFieldChange("phone-number", event.target.value)
                }
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            <TextField
              label="First Name"
              placeholder="Enter first name"
              variant="outlined"
              style={styles.input}
              value={formState.firstName}
              onChange={(event) =>
                onFormFieldChange("first-name", event.target.value)
              }
            />
            <TextField
              label="Last Name"
              placeholder="Enter last name"
              variant="outlined"
              style={styles.input}
              value={formState.lastName}
              onChange={(event) =>
                onFormFieldChange("last-name", event.target.value)
              }
            />
            <div style={{ ...styles.flexRow, alignItems: "flex-start" }}>
              <input
                type="checkbox"
                checked={consent}
                onChange={() => onCheckboxSelect()}
                style={{ marginRight: "2vw", flexShrink: 0 }}
              />
              <div>
                <label style={{ fontSize: "3.5vw", color: "#757575" }}>
                  I hereby consent to the collection and processing of my
                  personal information, including name, email, phone number, and
                  RT ID, as provided in this form.
                </label>
              </div>
            </div>
            <button
              disabled={isResultLoading || !consent}
              onClick={onSubmit}
              style={consent ? styles.submitButton : styles.submitButtonStyle}
            >
              {isResultLoading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                <>
                  Get Quilt<sup style={{ verticalAlign: "super" }}>beta</sup>
                </>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default QuiltPage;
