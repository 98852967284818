import React from "react";
import PlayStore from "../assets/android.svg";
import AppStore from "../assets/ios.svg";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  text1: {
    fontSize: "4vw",
    fontWeight: 600,
    lineHeight: "7vw",
    textAlign: "center",
    marginBottom: "5vw",
    marginTop: "10vw",
    color: "#1A0E35",
  },
  line: {
    width: "90%",
    borderBottom: "1px solid #ECF1F4",
    marginBottom: "20px",
  },
  text2: {
    fontSize: "6vw",
    fontWeight: 700,
    lineHeight: "7vw",
    textAlign: "center",
    marginBottom: "5vw",
    color: "#1A0E35",
  },
  image1: {
    width: "70%",
    maxWidth: "400px",
    marginBottom: "5vw",
  },
  image2: {
    width: "70%",
    maxWidth: "400px",
  },
};

const DownloadQuiltComponent: React.FC = () => {
  const onPlayStoreClick = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.QWLT.Quilt&pli=1";
  };

  const onAppStoreClick = () => {
    window.location.href =
      "https://apps.apple.com/us/app/quilt-games-health-tracker/id6462470118";
  };

  return (
    <div style={styles.container}>
      <div style={styles.text1}>Thanks for sharing your details</div>
      <div style={styles.line}></div>
      <div style={styles.text2}>
        Great, now download the<br></br>Quilt app here!
      </div>
      <img
        style={styles.image1}
        onClick={onPlayStoreClick}
        src={PlayStore}
        alt="Google Play"
      />
      <img
        style={styles.image2}
        onClick={onAppStoreClick}
        src={AppStore}
        alt="App Store"
      />
    </div>
  );
};

export default DownloadQuiltComponent;
