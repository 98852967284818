import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiClientContext } from "./utils/ApiContext";
import { createApiClient } from "./utils/ApiClient";
import QuiltPageContainer from "./container/QuiltPageContainer";
import DownloadQuiltComponent from "./components/DownloadQuiltPage";

const App: React.FC = () => {
  const apiClient = createApiClient(process.env.REACT_APP_HOST_IP!);

  return (
    <>
      <ToastContainer />
      <ApiClientContext.Provider value={apiClient}>
        <Router>
          <Routes>
            <Route path="/authorize" element={<QuiltPageContainer />} />
            <Route path="/download-app" element={<DownloadQuiltComponent />} />
          </Routes>
        </Router>
      </ApiClientContext.Provider>
    </>
  );
};

export default App;
