import {
  AddFormFieldState,
  AddRequestDTO,
} from "../container/QuiltPageContainer";

export const createRequestDTO = (
  formFieldState: AddFormFieldState,
  rtiId: string,
  adminDoctorId: string,
  clinicId: string
): AddRequestDTO => {
  return {
    firstName: formFieldState.firstName,
    lastName: formFieldState.lastName,
    countryCode: formFieldState.countryCode,
    ethnicity: "",
    email: "",
    phoneNumber: formFieldState.phoneNumber,
    externalPatientId: rtiId.toString(),
    doctorId: adminDoctorId,
    clinicId: clinicId,
    conditions: "",
  };
};
